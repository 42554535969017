import * as Sentry from '@sentry/nuxt'

const SENTRY_DSN = (useRuntimeConfig() as any)?.public?.sentry_dsn
if (SENTRY_DSN && SENTRY_DSN !== '') {
  Sentry.init({
    // If set up, you can use your runtime config here
    dsn: SENTRY_DSN,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}
